<template>
  <div class="pickup-point-viewer">
    <div class="row pickup-point-viewer-content relative z-index-0">
      <div class="pickup-point-list container col-xs-12 col-md-6 h500 overflow-scroll"
        :class="{ 'hidden-xs': mapVisible, 'hidden': mapVisible && mode === 'switch', 'col-md-12': mode === 'switch' }">
        <pickup-point-list @update="handlePickupPointListUpdated" @pickup-point-select="handlePickupPointSelectedList"
          :selected-pickup-point="selectedPickupPoint" :pickup-points="pickupPoints" />
      </div>
      <div class="col-xs-12 col-md-6 relative pickup-point-map-container h500"
        :class="{ 'hidden-xs': !mapVisible, 'hidden': !mapVisible && mode === 'switch', 'col-md-12': mode === 'switch' }">
        <pickup-point-map ref="mapComponent" @pickup-point-select="handlePickupPointSelectedMap"
          :selected-pickup-point="selectedPickupPoint" :pickup-points="pickupPoints" />
      </div>
    </div>
    <div class="row pickup-point-bottom-panel">
      <div class="col-xs-12 flex flex-gap-10 button-panel"
        :class="{ 'fixed-xs': fullScreenMobile, 'button-panel--switch': mode === 'switch', 'hidden-md': mode !== 'switch' }">
        <button-full @click.native="setMapVisible(true)" class="bottom-button"
          :class="{ 'bottom-button--white': !mapVisible }">
          {{ $t('Map') }}
        </button-full>
        <button-full @click.native="setMapVisible(false)" class="bottom-button"
          :class="{ 'bottom-button--white': mapVisible }">
          {{ $t('List') }}
        </button-full>
      </div>
    </div>
  </div>
</template>
<script>
import PickupPointList from './PickupPointList';
import PickupPointMap from './PickupPointMap';
import ButtonFull from '../../../theme/ButtonFull';
import config from 'config';

export default {
  name: 'PickupPointsViewer',
  components: {
    PickupPointMap,
    PickupPointList,
    ButtonFull
  },
  props: {
    pickupPoints: {
      type: Array,
      required: true
    },
    /**
     * Map mode indicating map layout behaviour
     *
     * Modes:
     * not set - map and list are on side by side when window size is 768px wide,  only one of them is visible on smaller window sizes and there are buttons to switch between them.
     * switch - behaves like if mode is not set on window sizes < 768px
     */
    mode: {
      type: String
    },
    /**
     * Sets button panel position to fixed in order to be visible when address bar show up
     */
    fullScreenMobile: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      mapVisible: true,
      selectedPickupPoint: undefined,
      oldSelectedPickupPoint: undefined
    };
  },
  methods: {
    displayPartnerPages() {
      return config.partnerData.displayPartnerPages;
    },
    handlePickupPointSelectedList(pickupPoint) {
      this.togglePickupPoint(pickupPoint);
    },
    handlePickupPointSelectedMap(pickupPoint) {
      this.selectedPickupPoint = pickupPoint;
      this.mapVisible = false;
      this.scrollToSelectedPickupPoint(pickupPoint);
    },
    scrollToSelectedPickupPoint(pickupPoint) {
      if (!pickupPoint) {
        return;
      }
      this.$nextTick(() => {
        const index = this.pickupPoints
          .map((pp, index) => ({ id: pp.id, index }))
          .find(pp => pp.id === pickupPoint.id)?.index;

        const container = document.querySelector('.pickup-point-list');
        const items = container.querySelectorAll('.pickup-point-list-item');

        if (items.length > index && index !== undefined) {
          const item = items[index];
          if (item) {
            item.scrollIntoView({
              behavior: 'smooth',
              block: 'nearest',
              inline: 'start'
            });
          }
        }
      });
    },
    togglePickupPoint(pickupPoint) {
      this.selectedPickupPoint = this.selectedPickupPoint === pickupPoint ? undefined : pickupPoint;
    },
    setMapVisible(visible) {
      this.mapVisible = visible;
    },
    handlePickupPointListUpdated() {
      if (this.oldSelectedPickupPoint !== this.selectedPickupPoint) {
        this.scrollToSelectedPickupPoint();
        this.oldSelectedPickupPoint = this.selectedPickupPoint;
      }
    },
  },
  mounted() {
    if (this.displayPartnerPages() && this.pickupPoints.length > 0) {
      this.togglePickupPoint(this.pickupPoints[0]);
    }
  },
  watch: {
    pickupPoints: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal.length > 0 && this.selectedPickupPoint === undefined) {
          this.togglePickupPoint(newVal[0]);
        }
      }
    }
  },
};

</script>

<style lang="scss" scoped>
.z-index-0 {
  z-index: 0;
}

.button-panel {
  display: flex;
  position: absolute;
  justify-content: center;
  bottom: 0;
  left: 0;
  width: 100%;
}

.button-panel--switch {
  display: flex;
  position: initial;
  justify-content: center;
  padding-top: 40px;
  width: 100%;
}

.h500 {
  height: 500px;
}

.hidden {
  display: none;
}

.pickup-point-viewer {
  height: 100%;
  width: 100%;
}

.pickup-point-bottom-panel {
  height: 50px;
  width: 100%;
}

.pickup-point-viewer-content {
  height: calc(100% - 50px);
}

@media (max-width: 768px) {
  .button-panel {
    display: flex;
  }
}

@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';

$color-silver: color(silver);

.bottom-button::v-deep .wrap {
  width: 100px;
  color: white;
}

.bottom-button--white::v-deep .wrap {
  background-color: $color-silver;
}

.overflow-scroll {
  overflow: scroll;
}

.bottom-button {
  min-width: unset;
  width: unset;
  padding-left: 0;
  padding-right: 0;
}

.flex-gap-10 {
  gap: 10px
}

.pickup-point-list {
  padding-right: 20px;
  padding-left: 10px;
}

@media (min-width: 768px) {
  .hidden-md {
    display: none;
  }
}

@media (max-width: 768px) {
  .pickup-point-map-container {
    width: 100%;
  }
}

@media (max-width: 572px) {
  .fixed-xs {
    position: fixed;
  }

  .pickup-point-map-container {
    height: 100%;
  }

  .pickup-point-list {
    padding: 0 0 0 10px;
  }
}
</style>
